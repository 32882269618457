html, body {
  height: 100%;
}

body { background-color: #353535}
.container { width: 800px; padding-top: 50px;}

.cursor-pointer { cursor: pointer; }

div.main {
  margin-bottom: '20px';
  min-height: calc(100vh - 70px);
}

div.content::after {
  content: "";
  background-image: url('/assets/img/STROOTEAM-ruglogo.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

nav {
  background-color: #ce5900;

  img {
    max-height: 40px;
  }
}

footer {
  z-index: 100000;
  position: sticky;
  bottom: 0px;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;

  audio {
    width: 100%;
  }
}

.card { opacity: 0.8}